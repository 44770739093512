/* globals $, fetch */

export default (function () {
  return {
    waitlist: function (event) {
      if (window.fetch === undefined) return

      var modal = $(this)
      modal.html('')
      fetch(modal.data('action'))
        .then(function (response) {
          if (response.status === 200) {
            return response.text()
          }
        })
        .then(function (text) {
          modal.html(text)
          return text
        })
    }
  }
}
)()
