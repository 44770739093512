/* globals $ */
import hotkeys from 'hotkeys'
import Typed from 'typed.js'
import modals from 'modals'

$(document)
  .on('show.bs.collapse', '#invoice-address', function () { this.removeAttribute('disabled') })
  .on('hide.bs.collapse', '#invoice-address', function () { this.setAttribute('disabled', '') })
  .on('show.bs.modal', '.add-to-waitlist', modals.waitlist)
  .on('show.bs.modal', '.add-to-interestlist', modals.waitlist)
  // .on('input', 'whdugu', doThis)
  .ready(function () {
    hotkeys.enable()

    var typedInput = document.querySelector('#home-search-field')
    if (typedInput === null) return

    var products = document.querySelectorAll('#product-inventory option')
    if (products.length === 0) return

    var productStrings = Array.from(products).map(function (option) {
      return option.value
    })

    var restorePlaceholder = function (pos, typed) {
      clearInterval(typed.timeout)
      typed.el.placeholder = this.placeholder
    }

    return new Typed('#home-search-field', {
      strings: productStrings,
      typeSpeed: 50,
      startDelay: 2000,
      shuffle: true,
      backDelay: 4000,
      loop: true,
      attr: 'placeholder',
      bindInputFocusEvents: true,
      contentType: null,
      onStop: restorePlaceholder.bind({ placeholder: typedInput.placeholder })
    })
  })
