import hotkeys from 'hotkeys-js'

export default (function () {
  const hideNone = function () {
    var noHotkeys = document.querySelector('#hotkeys h4')
    if (noHotkeys) noHotkeys.innerText = 'Sneltoetsen'
  }

  const bindSlash = function () {
    var focusDest = document.querySelector('.focus-slash-keypress')
    if (focusDest) {
      hotkeys('/', function (event, handler) {
        event.preventDefault()
        focusDest.focus()
      })

      var hint = document.querySelector('#hintSlash')
      if (hint) hint.classList.remove('away')

      hideNone()
    }
  }

  const coursePlanner = function () {
    if (/^\/admin/.test(window.location.pathname) && !/\/admin\/agenda#new$/.test(window.location.href)) {
      hotkeys('n', function (event, handler) {
        window.location.href = '/admin/agenda#new'
      })

      var hint = document.querySelector('#hintCoursePlanner')
      if (hint) hint.classList.remove('away')

      hideNone()
    }
  }

  return {
    enable: function () {
      bindSlash()
      coursePlanner()
    }
  }
})()
